import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import Helmet from 'react-helmet'

import AltertineLayout from './layouts/AltertineLayout'

import Button from '@material-ui/core/Button'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'

import { withNamespaces } from 'react-i18next'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

const IndexPage = ({ t }) => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)
  return (
    <AltertineLayout image="/assets/img/TENNIS_Btn.jpeg">
      <Helmet
        title={t('tennis.meta.title')}
        meta={[
          {
            name: 'description',
            content:t('tennis.meta.description')
          },
          { name: 'charset', content: 'UTF-8' }
        ]}
      />
      <section className="project_descr">
        <div className="container mt-100">
          <div className="row center">
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">{t('tennis.title')}</h2>
                <div className="module-subtitle">
                  {t('tennis.sub_title')}
                  <div
                      className="button-custom-container"
                      style={{ textAlign: 'center' }}
                    >
                      <a
                        href={t('tennis.lien_reservation')}
                        className="button-custom"
                        style={{ width: '100%' }}
                        target="_blank"
                        rel="noopener"
                      >
                        {' '}
                        {t('tennis.reservation')}{' '}
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        />
                      </a>
                    </div>

                    {t('tennis.sub_title2')}
                </div>
                <p className="module-subtitle">
                  {t('tennis.sub_title3')}
                </p>
              </div>
            </div>
          </div>

          <div className="row center">
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p style = {{ textAlign: 'center'}}>
                {t('tennis.description1')}
                
              </p>
              <div style={{ textAlign: 'center'}}>
              {t('tennis.description_point1')}<br></br>
              {t('paddel.description_point2')}
              {
                    /*
                <ul style={{ listStyle:'disc' }}>
                  <li style={{paddingLeft: "2em"}}>{t('tennis.description_point1')}</li>
                  
                        <li style={{paddingLeft: "2em"}}>{t('tennis.description_point2')}</li>
                        <li style={{paddingLeft: "2em"}}>{t('tennis.description_point3',{ name: '', escapeInterpolation: false })}</li>
                    
                  
                </ul>
                **/
              }
              </div>
            </div>
          </div>

          <div className="row center" style={{ marginTop: '5em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">{t('tennis.title5')}</h2>
              </div>
            </div>
          </div>
          <div className="row center" style={{ marginBottom: '7em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <div style={{ textAlign: 'left'}}>
              <p style={{ textAlign: 'left'}}>
                {t('tennis.title5_description1')}
                <ul style={{ listStyle:'disc' }}>
                  <li style={{paddingLeft: "2em"}}>{t('tennis.title5_description2')}</li>
                  <li style={{paddingLeft: "2em"}}>{t('tennis.title5_description3')}</li>
                </ul>
                </p>
              </div>
            </div>
          </div>

          {/*
          <div className="row center" style={{ marginTop: '5em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">{t('tennis.title3')}</h2>
              </div>
            </div>
          </div>
          
          <div className="row center" style={{ marginBottom: '7em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p style={{ textAlign: 'left'}}>
                {t('tennis.title3_description1')}
              </p >
              <p style={{ textAlign: 'left'}}>
                {t('tennis.title3_description2')}
              </p>

              <p style={{ textAlign: 'left'}}>
              {t('tennis.title3_contact_name')}<br />
              {t('tennis.title3_contact_tel')}<br />
              {t('tennis.title3_contact_email')}<br />
              </p>

              <div style={{ textAlign: 'left'}}>
                {t('tennis.title3_description3')}
                <ul style={{ listStyle:'disc' }}>
                  <li style={{paddingLeft: "2em"}}><a href={t('tennis.title3_lien1')} >{t('tennis.title3_lien1')}</a></li>
                  <li style={{paddingLeft: "2em"}}><a href={t('tennis.title3_lien2')} >{t('tennis.title3_lien2')}</a></li>
                </ul>
                
                
              </div>
            </div>
          </div>
          **/}

        </div>
      </section>
    </AltertineLayout>
  )
}

export default withNamespaces('home')(IndexPage)
